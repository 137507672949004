import { ApiCore } from './core';

class Api extends ApiCore {
  /** USER */
  async getUsers(params) {
    return this.client.get('v1/users', { params });
  }

  async createUser(data) {
    return this.client.post('v1/users', { data });
  }

  async updateUser(userId, data) {
    return this.client.patch(`v1/users/${userId}`, data);
  }

  async deleteUser(params) {
    return this.client.delete('v1/users', { params });
  }

  async getAllPartnersOptions(params) {
    return this.client.get('v1/users/partners/options', {
      params,
    });
  }

  async getTopCountries(userId, params) {
    return this.client.get(`v1/users/${userId}/top-countries`, {
      params,
    });
  }
  /** USERS */
  async getAccountTransfersOptions(userId) {
    return this.client.get(`v1/users/${userId}/account-transfers/options`);
  }

  async createAccountTransfer(userId, data) {
    return this.client.post(`v1/users/${userId}/account-transfers`, data);
  }

  async getAccountTransfersByUserId(userId, params) {
    return this.client.get(`v1/users/${userId}/account-transfers`, { params });
  }

  async getUserBalance(userId) {
    return this.client.get(`v1/users/${userId}/balance`);
  }

  async getUserBalanceDetails(userId) {
    return this.client.get(`v1/users/${userId}/balance/details`);
  }

  async getUserBalanceHistory(userId, params) {
    return this.client.get(`v1/users/${userId}/balance/history`, { params });
  }

  async getUserBalanceHistoryLimits(userId, params) {
    return this.client.get(`v1/users/${userId}/balance/history/limits`, {
      params,
    });
  }

  async getUserBalanceHistoryReport(userId, params) {
    return this.client.get(`v1/users/${userId}/balance/history/report`, {
      params,
    });
  }

  async getUserManager(userId) {
    return this.client.get(`v1/users/${userId}/manager`);
  }

  async updateUserPassword(userId, data) {
    return this.client.put(`v1/users/${userId}/password`, data);
  }

  async updatePartnerSettings(userId, data) {
    return this.client.patch(`v1/users/${userId}/partner-settings`, data);
  }

  async getPartnerSettingsByUserId(userId) {
    return this.client.get(`v1/users/${userId}/partner-settings`);
  }

  async getPartnerEarnings(userId, params) {
    return this.client.get(`v1/users/${userId}/commissions`, { params });
  }

  async getPartnerEarningsLimits(userId, params) {
    return this.client.get(`v1/users/${userId}/commissions/limits`, { params });
  }

  async createUserPayment(userId, data) {
    return this.client.post(`v1/users/${userId}/payments`, data);
  }

  async createTfaCode(userId) {
    return this.client.post(`v1/users/${userId}/auth/tfa/code`);
  }

  async verifyTfaCodeAndGetAccessToken(userId, data) {
    return this.client.post(
      `v1/users/${userId}/auth/tfa/code/verification`,
      data,
    );
  }

  async enableTfa(userId, data) {
    return this.client.post(`v1/users/${userId}/auth/tfa`, data);
  }

  async disableTfa(userId) {
    return this.client.delete(`v1/users/${userId}/auth/tfa`);
  }

  async getUserById(userId) {
    return this.client.get(`v1/users/${userId}`);
  }

  async getCommissionsReport(params) {
    return this.client.get(`v1/users/commissions/report`, { params });
  }

  async getCommissionsReportLimits(params) {
    return this.client.get(`v1/users/commissions/report/limits`, { params });
  }

  async approveCpaCommissions(data) {
    return this.client.put(`v1/cpa-commissions/approval`, { data });
  }

  async getUserTradesCount(userId, params) {
    return this.client.get(`v1/users/${userId}/trades/count`, {
      params,
    });
  }

  async loginAsPartner(userId) {
    return this.client.post(`v1/users/${userId}/auth/login`);
  }

  async getUserPayments(userId, params) {
    return this.client.get(`v1/users/${userId}/payments`, { params });
  }

  async getUserPaymentsLimits(userId, params) {
    return this.client.get(`v1/users/${userId}/payments/limits`, { params });
  }

  async getPartnerClients({ userId, params }) {
    return this.client.get(`v1/users/${userId}/accounts`, { params });
  }

  async getPartnerClientsLimits(userId, params) {
    return this.client.get(`v1/users/${userId}/accounts/limits`, { params });
  }

  async getTradesSymbolsOverview(userId, params) {
    return this.client.get(
      `v1/users/${userId}/trades/symbols/breakdown-series`,
      {
        params,
      },
    );
  }

  async getUserTrades(userId, params) {
    return this.client.get(`v1/users/${userId}/trades`, { params });
  }

  async getUserTradesLimits(userId, params) {
    return this.client.get(`v1/users/${userId}/trades/limits`, {
      params,
    });
  }

  async getUserTradesTotalCommissionsTimeSeries(userId, params) {
    return this.client.get(
      `v1/users/${userId}/trades/commissions/time-series`,
      { params },
    );
  }

  async getUsersRegistrationsReport(params) {
    return this.client.get('v1/users/registrations/report', { params });
  }

  async getUsersRegistrationsReportLimits(params) {
    return this.client.get('v1/users/registrations/report/limits', { params });
  }

  /** ACCOUNT-TRANSFERS */
  async getAccountTransfers(params) {
    return this.client.get(`v1/account-transfers`, { params });
  }

  async getAccountTransfersLimits(params) {
    return this.client.get(`v1/account-transfers/limits`, { params });
  }

  async approveAccountTransferTaxInvoice(accountTransferId) {
    return this.client.put(
      `v1/account-transfers/${accountTransferId}/tax-invoice-approval`,
    );
  }

  /** BALANCE */
  async getBalancesReport(params) {
    return this.client.get(`v1/users/balance/report`, { params });
  }

  /** BS-USERS */
  async getBsUsersRegistrationsReport(params) {
    return this.client.get(`v1/bs-users/registrations/report`, { params });
  }

  async getBsUsersById(userId, params) {
    return this.client.get(`v1/cpa-users/${userId}/bs-users`, { params });
  }

  async getBsUserLimitsById(userId, params) {
    return this.client.get(`v1/cpa-users/${userId}/bs-users/limits`, {
      params,
    });
  }

  /** PARTNERS-GROUP */
  async getPartnerGroups(params) {
    return this.client.get('v1/partners-groups', { params });
  }

  async createPartnerGroups(data) {
    return this.client.post('v1/partners-groups', data);
  }

  async updatePartnerGroups(id, data) {
    return this.client.patch(`v1/partners-groups/${id}`, data);
  }

  async deletePartnerGroups(data) {
    return this.client.delete('v1/partners-groups', data);
  }

  /** CPA-QUALIFIER */

  async createCpaQualifier(data) {
    return this.client.put('v1/cpa-qualifiers', data);
  }

  async getCpaQualifier(params) {
    return this.client.get('v1/cpa-qualifiers', { params });
  }

  /** CPA-TIER */

  async createCpaTier(groupId, data) {
    return this.client.put(`v1/partners-groups/${groupId}/cpa-tiers`, data);
  }

  async getTiers(groupId) {
    return this.client.get(`v1/partners-groups/${groupId}/cpa-tiers`);
  }

  async getChangedTiers(groupId, data) {
    return this.client.post(
      `v1/partners-groups/${groupId}/cpa-tiers/changed`,
      data,
    );
  }

  /** ACCOUNTS */
  async getTradesLimitsByAccountId(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/trades/limits`, {
      params,
    });
  }

  async getAccountById(accountId) {
    return this.client.get(`v1/accounts/${accountId}`);
  }

  async getAccountsTradesSymbolsOverview(accountId, params) {
    return this.client.get(
      `v1/accounts/${accountId}/trades/symbols/breakdown-series`,
      {
        params,
      },
    );
  }

  async getAccountsTrades(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/trades`, { params });
  }

  async getAccountsWithdrawals(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/bs-withdrawals`, {
      params,
    });
  }

  async getAccountsWithdrawalsLimits(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/bs-withdrawals/limits`, {
      params,
    });
  }

  async getAccountsDeposits(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/deposits`, { params });
  }

  async getAccountsDepositsLimits(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/deposits/limits`, {
      params,
    });
  }

  async getTopAccounts(userId, params) {
    return this.client.get(`v1/users/${userId}/accounts/top-performing`, {
      params,
    });
  }

  /** ACCOUNT-SNAPSHOTS */
  async getAccountMetrics(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/metrics`, { params });
  }

  async getActiveAccountsMetricByUserId(userId, params) {
    return this.client.get(`v1/users/${userId}/metrics/active-accounts`, {
      params,
    });
  }

  async getActiveAccountsMetricTimeSeriesByUserId(userId, params) {
    return this.client.get(
      `v1/users/${userId}/metrics/active-accounts/time-series`,
      { params },
    );
  }
  /** ACCOUNT-SNAPSHOTS */

  /** PAYMENTS */
  async getPayments(params) {
    return this.client.get(`v1/payments`, { params });
  }

  async getPaymentsLimits(params) {
    return this.client.get(`v1/payments/limits`, { params });
  }

  /** COUNTRIES */
  async getCountryInfo() {
    return this.client.get(`v1/countries/current`);
  }

  /** AUTH */
  async getAuthenticatedUser() {
    return this.client.get(`v1/auth`);
  }

  async register(data) {
    return this.client.post(`v1/auth/register`, data);
  }

  async login(data) {
    return this.client.post(`v1/auth/login`, data);
  }

  async resetPassword(data) {
    return this.client.post('v1/auth/reset-password', data);
  }

  /** BANK-WIRES */
  async rejectBankWire(bankWireId) {
    return this.client.put(`v2/bank-wires/${bankWireId}/rejection`);
  }

  async approveBankWireTaxInvoice(bankWireId) {
    return this.client.put(`v2/bank-wires/${bankWireId}/tax-invoice-approval`);
  }

  async requestBankWire(params) {
    return this.client.get('v1/bank-wires/request', { params });
  }

  async requestConfirmBankWire(params) {
    return this.client.get('v1/bank-wires/request-confirm', { params });
  }

  async userWithdrawsBankWire(params) {
    return this.client.get('v1/bank-wires/user-withdraws', { params });
  }

  async userUsersWithdrawsBankWire(params) {
    return this.client.get('v1/bank-wires/users-withdraws', { params });
  }

  async userUsersWithdrawsBankWireLimits(params) {
    return this.client.get('v1/bank-wires/users-withdraws-limits', { params });
  }

  /** TRADES */
  async getTrades(params) {
    return this.client.get('v1/trades/report', { params });
  }

  async getTradesLimits(params) {
    return this.client.get('v1/trades/report/limits', { params });
  }

  async getTradesSymbols(params) {
    return this.client.get('v1/trades/symbols', { params });
  }

  async getTradesSecuritiesGroups(params) {
    return this.client.get('v1/trades/securities-groups', { params });
  }

  /** FEES */
  async getFees(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/fees`, { params });
  }

  async getFeesLimits(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/fees/limits`, { params });
  }

  async getClientFees(userId, params) {
    return this.client.get(`v1/users/${userId}/fees`, { params });
  }

  async getClientFeesLimits(userId, params) {
    return this.client.get(`v1/users/${userId}/fees/limits`, { params });
  }

  async getFeesSymbols(params) {
    return this.client.get('v1/fee-symbols', { params });
  }

  async getFeesReport(params) {
    return this.client.get(`v1/fees/report`, { params });
  }

  async getFeesReportLimits(params) {
    return this.client.get(`v1/fees/report/limits`, { params });
  }

  async getVolumeOverviewBreakDownTimeSeries(accountId, params) {
    return this.client.get(
      `v1/accounts/${accountId}/fees/symbols/breakdown-series`,
      { params },
    );
  }

  async getFeesSymbolsOverview(userId, params) {
    return this.client.get(`v1/users/${userId}/fees/symbols/breakdown-series`, {
      params,
    });
  }

  async getUserFeesCount(userId, params) {
    return this.client.get(`v1/users/${userId}/fees/count`, { params });
  }

  async getUserFeesTotalCommissionsTimeSeries(userId, params) {
    return this.client.get(`v1/users/${userId}/fees/commissions/time-series`, {
      params,
    });
  }
  /** CPA-COMMISSIONS */
  async getCpaCommissionsReport(params) {
    return this.client.get('v1/cpa-commissions/report', { params });
  }

  async getCpaCommissionsReportLimits(params) {
    return this.client.get(`v1/cpa-commissions/report/limits`, { params });
  }

  async getCpaCommissions(params) {
    return this.client.get(`v1/cpa-commissions`, { params });
  }

  /** COMMISSION-SETTINGS */
  async getCommissionSettings(params) {
    return this.client.get('v1/commission-settings', { params });
  }

  async createCommissionSettings(data) {
    return this.client.post('v1/commission-settings', data);
  }

  /** LOGS */
  async getLogs(params) {
    return this.client.get('v1/logs', { params });
  }

  /** COMMISSIONS */
  async getUserCommissionsTimeSeries(userId, params) {
    return this.client.get(`v1/users/${userId}/commissions/time-series`, {
      params,
    });
  }

  async getAccountCommissionsTimeSeries(accountId, params) {
    return this.client.get(`v1/accounts/${accountId}/commissions/time-series`, {
      params,
    });
  }
  /** COMMISSIONS */

  /** PARTNER SNAPSHOTS */
  async getPartnersMetrics(params) {
    return this.client.get(`v1/metrics`, {
      params,
    });
  }

  async getPartnerMetricsByUserId(userId, params) {
    return this.client.get(`v1/users/${userId}/metrics`, {
      params,
    });
  }

  async getPartnersMetricsTimeSeries(params) {
    return this.client.get(`v1/metrics/time-series`, {
      params,
    });
  }

  async getPartnerMetricsTimeSeriesByUserId(userId, params) {
    return this.client.get(`v1/users/${userId}/metrics/time-series`, {
      params,
    });
  }
  /** PARTNER SNAPSHOTS */
}

export const api = new Api();
